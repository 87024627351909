











































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import MultiselectV2 from '@/app/ui/components/MultiselectV2/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import {
  EnumStatus,
  IFormDetail,
  IHistoryFields,
  IOptions,
} from '@/app/infrastructures/misc/Constants/manageCourierDedicated'
import NestedMultiSelect from '../../../components/NestedMultiSelect/index.vue'
import dedicatedController from '@/app/ui/controllers/ManageCourierDedicatedController'
import {
  GeoAddress,
  ManageCourierDedicatedDetail,
} from '@/domain/entities/ManageCourierDedicated'
import { Utils } from '@/app/infrastructures/misc'

interface ILocation {
  name: string
  isChecked: boolean
  geoAddressId: string
  isParentNotHidden: boolean
  isDisabled(): boolean
  setIsChecked(state: boolean): void
  children: Array<ILocation>
}

@Component({
  components: {
    Button,
    DropdownSelect,
    TextInput,
    DateTimePicker,
    MultiselectV2,
    LoadingOverlay,
    NestedMultiSelect,
  },
})
export default class DetailAreaDedicatedPage extends Vue {
  dedicatedController = dedicatedController
  courierList: Array<IOptions> = []
  locationData: Array<ILocation> = []

  courierParameters = {
    page: 1,
    perPage: 10,
    q: '',
    sortBy: 'ASC',
    driverType: 'KVP',
    courierStatus: '',
  }

  geoAddressParameters = {
    search: '',
  }

  form: IFormDetail = {
    areaName: '-',
    location: [],
    courierMP: [],
    courierCA: [],
    courierClient: [],
    offerMethod: '',
    status: '',
    createdAt: '-',
    updatedAt: '-',
    createdBy: '-',
    updatedBy: '-',
  }

  historyFields: Array<IHistoryFields> = [
    {
      name: 'Dibuat Tanggal',
      formKey: 'createdAt',
    },
    {
      name: 'Terakhir Diubah',
      formKey: 'updatedAt',
    },
    {
      name: 'Dibuat Oleh',
      formKey: 'createdBy',
    },
    {
      name: 'Diubah Oleh',
      formKey: 'updatedBy',
    },
  ]

  created(): void {
    this.fetchAreaDedicatedDetail()
  }
  
  get geoAddressParams(): Record<string, string> {
    return this.geoAddressParameters
  }

  private fetchAreaDedicatedDetail(): void {
    this.dedicatedController.getDetail(Number(this.$route.params.id))
  }

  private fetchGeoAddress(): void {
    this.dedicatedController.getGeoAddress(this.geoAddressParams)
  }

  private onEdit(id: string): void {
    this.$router.push(
      `/offer/manage-courier-dedicated/area-dedicated/edit/${id}`
    )
  }

  private locationMapper(
    data: GeoAddress,
    existingIds: Array<number>
  ): ILocation {
    return {
      name: data.name || '',
      isChecked: false,
      geoAddressId: data.geoAddressId ? String(data.geoAddressId) : '',
      isParentNotHidden: false,
      isDisabled(): boolean {
        if (
          data.areaId &&
          data.geoAddressId &&
          data.areaId !== 0 &&
          !existingIds.includes(Number(data.geoAddressId))
        ) {
          return true
        } else if (data.children && data.children.length !== 0) {
          let childDisabledCount = 0

          this.children.forEach(child => {
            if (child.isDisabled()) {
              childDisabledCount++
            }
          })

          if (childDisabledCount === this.children.length) {
            return true
          }
        }

        return false
      },
      setIsChecked(state: boolean): void {
        this.isChecked = state
      },
      children: data.children
        ? data.children.map(child => {
            return this.locationMapper(child, existingIds)
          })
        : [],
    }
  }

  private setLocationState(
    locData: Array<ILocation>,
    idList: Array<number>
  ): void {
    locData.forEach(location => {
      if (
        location.geoAddressId &&
        idList.includes(Number(location.geoAddressId))
      ) {
        location.setIsChecked(true)
      }

      if (location.children.length !== 0) {
        this.setLocationState(location.children, idList)
      }
    })
  }

  @Watch('dedicatedController.dataGeoAddressList')
  setGeoAddressData(data: Array<GeoAddress>): void {
    this.locationData = data.map(item => {
      return this.locationMapper(item, this.form.location)
    })

    this.setLocationState(this.locationData, this.form.location)
  }

  @Watch('dedicatedController.dataManageCourierDedicatedDetail')
  setDedicatedDetailData(data: ManageCourierDedicatedDetail): void {
    let courierMp: Array<IOptions> = []
    let courierCa: Array<IOptions> = []
    let courierClient: Array<IOptions> = []

    if (data.courierMp) {
      courierMp = data.courierMp.map(courier => {
        return {
          label: `[${courier.courierId}] ${courier.name} (${courier.courierType}) ${courier.phone} • ${courier.partnerName}`,
          value: courier.courierId,
        }
      })
    }

    if (data.courierCa) {
      courierCa = data.courierCa.map(courier => {
        return {
          label: `[${courier.courierId}] ${courier.name} (${courier.courierType}) ${courier.phone} • ${courier.partnerName}`,
          value: courier.courierId,
        }
      })
    }

    if (data.courierCorporate) {
      courierClient = data.courierCorporate.map(courier => {
        return {
          label: `[${courier.courierId}] ${courier.name} (${courier.courierType}) ${courier.phone} • ${courier.partnerName}`,
          value: courier.courierId,
        }
      })
    }

    const res: IFormDetail = {
      areaName: data.areaName || '',
      location: data.geoAddressIds ? data.geoAddressIds : [],
      courierMP: courierMp,
      courierCA: courierCa,
      courierClient: courierClient,
      offerMethod: data.offerType || '-',
      status: data.isActive ? EnumStatus.AKTIF : EnumStatus.NONAKTIF,
      createdAt: data.createdAt
        ? Utils.formatTimeZone(Utils.formatDateWithIDLocale(data.createdAt))
        : '-',
      updatedAt: data.updatedAt
        ? Utils.formatTimeZone(Utils.formatDateWithIDLocale(data.updatedAt))
        : '-',
      createdBy: data.createdBy || '-',
      updatedBy: data.updatedBy || '-',
    }

    this.form = res

    this.fetchGeoAddress()
  }
}
